import React from 'react'
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import './assets/libs/@mdi/font/css/materialdesignicons.min.css';
import Index from './pages/index';
import Navbar from './component/Navbar/navbar';
import Topbar from './component/Topbar';

// import Product from './pages/index/product';
// import Accounting from './pages/index/accounting';
// import Inventory from './pages/index/inventory';
// import Crm from './pages/index/crm';
// import Hrm from './pages/index/hrm';
// import Reporting from './pages/index/reporting';
// import Solution from './pages/index/solution';
// import Pricing from './pages/index/pricing';
// import Resources from './pages/index/resources';
// import Feature from './pages/index/feature';
// import Hardware from './pages/index/hardware';
// import Android_pos from './pages/index/android_pos';
// import Windows_pos from './pages/index/windows_apps';
// import Services from './pages/index/services';
// import MobileApps from './pages/index/mobile_apps';
// import HandyPos from './pages/index/handy_pos';
// import Label_printer from './pages/index/label_printer';
// import Thermal_printer from './pages/index/thermal_printer';
// import Barcode_scanner from './pages/index/barcode_scanner';
// import Accessories from './pages/index/other_accessories';
// import TvApps from './pages/index/tv_apps';
// import Website from './pages/index/website';
// import FoodRecipe from './pages/index/food_recipe';
// import VideoEncryption from './pages/index/video_encryption';
// import DrmSolution from './pages/index/drm_solution';
// import LiveStreamnig from './pages/index/live_streaming';
// import PodCats from './pages/index/podcats';
// import StorageApi from './pages/index/storage_api';
// import VirtualTeaching from './pages/index/virtual_teaching';
// import OnlinePlateform from './pages/index/online_plateform';
// import LiveStudio from './pages/index/live_studio';
// import LiveTeaching from './pages/index/live_teaching';
// import SchoolCOllege from './pages/index/school_college';
// import OnlineCourses from './pages/index/online_courses';
// import Creators from './pages/index/creators';
// import Influencer from './pages/index/influencer';
// import CorporateTraining from './pages/index/corporate_training';
// import VocationTraining from './pages/index/vocation_training';
// import Membership from './pages/index/membership';
// import Communities from './pages/index/communities';
// import Subscribtion from './pages/index/subscribtion';
// import Education from './pages/index/education';
// import Finace from './pages/index/finace';
// import Music from './pages/index/music';
// import ArtCraft from './pages/index/art_craft';
// import Automobile from './pages/index/automobile';
// import SoftwareTechnology from './pages/index/software_technology';
// import Beauty from './pages/index/beauty';
// import Spirituality from './pages/index/spirituality';
// import Ngos from './pages/index/ngos';

//// features pages  /////
// import Multi_business from './pages/index/multi_business';
// import Multi_location from './pages/index/multi_location';
// import Multi_language from './pages/index/multi_language';
// import Express_checkout from './pages/index/express_checkout';
// import Barcode_sticker from './pages/index/barcode_sticker';
// import Feature_reporting from './pages/index/feature_reporting';
// import Easy_installation from './pages/index/easy_installation';
// import Social_blog from './pages/index/social_blog';
// import Detailed_documention from './pages/index/detailed_documention';







//// Industries pages  ////
// import Departmental from './pages/index/departmental';
// import Supermarket from './pages/index/supermarket';
// import Grocery from './pages/index/grocery';
// import Toy from './pages/index/toy';
// import Stationery from './pages/index/stationery';
// import Hardware_store from './pages/index/hardware_store';
// import Sanitary from './pages/index/sanitary';
// import Liquor from './pages/index/liquor';
// import Retail from './pages/index/retail';
// import Garment from './pages/index/garment';
// import Footwear from './pages/index/footwear';
// import Fashion_store from './pages/index/fashion_store';
// import Pharmacy from './pages/index/pharmacy';
// import Jewellery from './pages/index/jewellery';
// import Industries_pos from './pages/index/industries_pos';
// import Automobile_store from './pages/index/automobile_store';
// import Book_store from './pages/index/book_store';
// import Electrical from './pages/index/electrical';
// import Electronics from './pages/index/electronics';
// import Services_center from './pages/index/services_center';
// import Salon from './pages/index/salon';
// import Mobile_store from './pages/index/mobile_store';
// import Computer_store from './pages/index/computer_store';
// import Repair_shop from './pages/index/repair_shop';
// import Manufacturing from './pages/index/manufacturing';
// import Restaurant from './pages/index/restaurant';
// import Furniture from './pages/index/furniture';

// Outlet Types ///

// import Fine_dining from './pages/index/fine_dining';
// import Hotel from './pages/index/hotel';
// import Cafe_bakery from './pages/index/cafe_bakery';
// import Icecream from './pages/index/icecream';
// import Food_truck from './pages/index/food_truck';
// import Restaurant_chain from './pages/index/restaurant_chain';
// import Restaurant from './pages/index/restaurant';
// import Bar_lounge from './pages/index/bar_lounge';
// import Qsr from './pages/index/qsr';
// import Pizza from './pages/index/pizza';




// import MultipleLanding from './pages/index/multiple_landing';
// import CreateGroup from './pages/index/create_group';
// import MembershipSite from './pages/index/membership_site';
// import WhiteLabel from './pages/index/white_label';
// import WhiteLable2 from './pages/index/white_lable_without_app';
// import MagicCheckout from './pages/index/magic_checkout';
// import TieredPricing from './pages/index/tiered_pricing';
// import SmartUpsell from './pages/index/smart_upsell';
// import FeatureBlogs from './pages/index/features_blogs';
// import Gamification from './pages/index/gamification';
// import VideoAnalytis from './pages/index/video_analytis';
// import DigitalDownloads from './pages/index/digital_downloads';
// import Integrated from './pages/index/integrated';
// import SalesReport from './pages/index/sales_report';
// import StudentProgress from './pages/index/student_progress';
// import Integration from './pages/index/integration';
// import DripCouse from './pages/index/drip_courses';
// import Seo from './pages/index/seo';
// import StudentReferral from './pages/index/student_referrals';
// import FeaturePodcats from './pages/index/feature_podcats';
// import DisscusionForm from './pages/index/disscusion_form';
// import AffiliateMarket from './pages/index/affilate_marketing';
// import PopupBuilder from './pages/index/popup_builder';
// import FeatureSusbcribtion from './pages/index/features_subscribtion';
// import WalletRechange from './pages/index/wallet_rechage';
// import GetStarted from './pages/index/get-started';

// import FoodRecipe from './pages/foodRecipe';

import Blog from './pages/blog/blog';

import Switch from './component/Switch';




export default function App() {
    return (
        <BrowserRouter>
        <Switch/>
            <Routes>
                <Route  path="/" element={<Index />} />
                <Route exact path="/navbar" element={<Navbar />} />
                {/* <Route path="/" element={<Navigate to="/index" />} /> */}
                {/* <Route exact path="/get-started" element={<GetStarted />} /> */}


                {/* <Route exact path="/pos" element={<Product />} />
                <Route exact path="/accounting" element={<Accounting />} />
                <Route exact path="/inventory" element={<Inventory />} />
                <Route exact path="/crm" element={<Crm />} />
                <Route exact path="/hrm" element={<Hrm />} />
                <Route exact path="/reporting" element={<Reporting />} />
                <Route exact path="/Solution" element={<Solution />} />
                <Route exact path="/Pricing" element={<Pricing />} />
                <Route exact path="/Resources" element={<Resources />} />
                <Route exact path="/Feature" element={<Feature />} />
                <Route exact path="/Hardware" element={<Hardware />} />
                <Route exact path="/Windows_pos" element={<Windows_pos />} />
                <Route exact path="/Android_pos" element={<Android_pos />} />
                <Route exact path="/Services" element={<Services />} />
                <Route exact path="/Mobile_apps" element={<MobileApps />} />
                <Route exact path="/Handy_pos" element={<HandyPos />} />
                <Route exact path="/Label_printer" element={<Label_printer />} />
                <Route exact path="/Thermal_printer" element={<Thermal_printer />} />
                <Route exact path="/Barcode_scanner" element={<Barcode_scanner />} />
                <Route exact path="/Accessories" element={<Accessories />} />
                <Route exact path="/Tv_apps" element={<TvApps />} />
                <Route exact path="/Website" element={<Website />} />
                <Route exact path="/Food_recipe" element={<FoodRecipe />} />
                <Route exact path="/Video_encryption" element={<VideoEncryption />} />
                <Route exact path="/Drm_solution" element={<DrmSolution />} />
                <Route exact path="/Live_streaming" element={<LiveStreamnig />} />
                <Route exact path="/podcats" element={<PodCats />} />
                <Route exact path="/Storage_api" element={<StorageApi />} />
                <Route exact path="/Online_plateform" element={<OnlinePlateform />} />
                <Route exact path="/Virtual_teaching" element={<VirtualTeaching />} />
                <Route exact path="/Live_teaching" element={<LiveTeaching />} />
                <Route exact path="/live_studio" element={<LiveStudio />} /> */}


                {/* Features pages */}
                {/* <Route exact path="/multi_business" element={<Multi_business />} />
                <Route exact path="/multi_location" element={<Multi_location />} />
                <Route exact path="/multi_language" element={<Multi_language />} />
                <Route exact path="/express_checkout" element={<Express_checkout />} />
                <Route exact path="/barcode_sticker" element={<Barcode_sticker />} />
                <Route exact path="/feature_reporting" element={<Feature_reporting />} />
                <Route exact path="/easy_installation" element={<Easy_installation />} />
                <Route exact path="/social_blog" element={<Social_blog />} />
                <Route exact path="/detailed_documention" element={<Detailed_documention />} /> */}



                {/* Insdusties pages */}
                {/* <Route exact path="/departmental" element={<Departmental />} />
                <Route exact path="/supermarket" element={<Supermarket />} />
                <Route exact path="/grocery" element={<Grocery />} />
                <Route exact path="/toy" element={<Toy />} />
                <Route exact path="/stationery" element={<Stationery />} />
                <Route exact path="/hardware_store" element={<Hardware_store />} />
                <Route exact path="sanitary" element={<Sanitary />} />
                <Route exact path="/liquor" element={<Liquor />} />
                <Route exact path="/retail" element={<Retail />} />
                <Route exact path="/garment" element={<Garment />} />
                <Route exact path="/footwear" element={<Footwear />} />
                <Route exact path="/fashion_store" element={<Fashion_store />} />
                <Route exact path="/pharmacy" element={<Pharmacy />} />
                <Route exact path="/jewellery" element={<Jewellery />} />
                <Route exact path="/industries_pos" element={<Industries_pos />} />
                <Route exact path="/automobile_store" element={<Automobile_store />} />
                <Route exact path="/book_store" element={<Book_store />} />
                <Route exact path="/electrical" element={<Electrical />} />
                <Route exact path="/electronics" element={<Electronics />} />
                <Route exact path="/services_center" element={<Services_center />} />
                <Route exact path="/salon" element={<Salon />} />
                <Route exact path="/mobile_store" element={<Mobile_store />} />
                <Route exact path="/computer_store" element={<Computer_store />} />
                <Route exact path="/repair_shop" element={<Repair_shop />} />
                <Route exact path="/manufacturing" element={<Manufacturing />} />
                <Route exact path="/restaurant" element={<Restaurant />} />
                <Route exact path="/furniture" element={<Furniture />} /> */}

                {/* Outle Types */}
                {/* <Route exact path="/restaurant" element={<Restaurant />} />
                <Route exact path="/bar_lounge" element={<Bar_lounge />} />
                <Route exact path="/qsr" element={<Qsr />} />
                <Route exact path="/pizza" element={<Pizza />} />
                <Route exact path="/fine_dining" element={<Fine_dining />} />
                <Route exact path="/hotel" element={<Hotel />} />
                <Route exact path="/cafe_bakery" element={<Cafe_bakery />} />
                <Route exact path="/icecream" element={<Icecream />} />
                <Route exact path="/food_truck" element={<Food_truck />} />
                <Route exact path="/restaurant_chain" element={<Restaurant_chain />} /> */}



                {/* <Route exact path='/school_college' element={<SchoolCOllege />} />
                <Route exact path='/online_courses' element={<OnlineCourses />} />
                <Route exact path='/creators' element={<Creators />} />
                <Route exact path='/influencer' element={<Influencer />} />
                <Route exact path='/corporate_training' element={<CorporateTraining />} />
                <Route exact path='/vocation_training' element={<VocationTraining />} />
                <Route exact path='/membership' element={<Membership />} />
                <Route exact path='/communities' element={<Communities />} />
                <Route exact path='/subscribtion' element={<Subscribtion />} />
                <Route exact path='/education' element={<Education />} />
                <Route exact path='/finace' element={<Finace />} />
                <Route exact path='/music' element={<Music />} />
                <Route exact path='/art_craft' element={<ArtCraft />} />
                <Route exact path='/automobile' element={<Automobile />} />
                <Route exact path='/software_technology' element={<SoftwareTechnology />} />
                <Route exact path='/beauty' element={<Beauty />} />
                <Route exact path='/spirituality' element={<Spirituality />} />
                <Route exact path='/ngos' element={<Ngos />} /> */}



                {/* <Route exact path='/multiple_landing' element={<MultipleLanding />} />
                <Route exact path='/create_group' element={<CreateGroup />} />
                <Route exact path='/membership_site' element={<MembershipSite />} />
                <Route exact path='/white_label' element={<WhiteLabel />} />
                <Route exact path='/white_lable_without_app' element={<WhiteLable2 />} />
                <Route exact path='/magic_checkout' element={<MagicCheckout />} />
                <Route exact path='/tiered_pricing' element={<TieredPricing />} />
                <Route exact path='/smart_upsell' element={<SmartUpsell />} />
                <Route exact path='/features_blogs' element={<FeatureBlogs />} />
                <Route exact path='/gamification' element={<Gamification />} />
                <Route exact path='/video_analytis' element={<VideoAnalytis />} />
                <Route exact path='/digital_downloads' element={<DigitalDownloads />} />
                <Route exact path='/integrated' element={<Integrated />} />
                <Route exact path='/sales_report' element={<SalesReport />} />
                <Route exact path='/student_progress' element={<StudentProgress />} />
                <Route exact path='/integration' element={<Integration />} />
                <Route exact path='/drip_courses' element={<DripCouse />} />
                <Route exact path='/seo' element={<Seo />} />
                <Route exact path='/student_referrals' element={<StudentReferral />} />
                <Route exact path='/feature_podcats' element={<FeaturePodcats />} />
                <Route exact path='/disscusion_form' element={<DisscusionForm />} />
                <Route exact path='/affilate_marketing' element={<AffiliateMarket />} />
                <Route exact path='/popup_builder' element={<PopupBuilder />} />
                <Route exact path='/features_subscribtion' element={<FeatureSusbcribtion />} />
                <Route exact path='/wallet_rechage' element={<WalletRechange />} /> */}

                <Route exact path="/blog" element={<Blog />} />
            </Routes>

        </BrowserRouter>
    )
}
